import React, { useState } from "react";
import axios from "axios"; // For HTTP requests (you'll need to install axios with npm/yarn)
import { Link, useNavigate } from "react-router-dom"; /// Import useHistory
import CONFIG from "../config";

// LoginForm component
const LoginForm = () => {
  const [clientEmail, setClientEmail] = useState("");
  const [clientPassword, setClientPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const navigate = useNavigate(); // Get the navigate function

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    try {
      const postData = new FormData();
      postData.append("clientEmail_login", clientEmail);
      postData.append("user_pw", clientPassword);

      const response = await axios.post(
        `${CONFIG.BASE_URL}/login_submit`,
        postData,
        {}
      );

      const data = response.data;
      console.log(data);

      if (data["auth"] === "1") {
        localStorage.setItem("username", data["user_name"]);
        localStorage.setItem("userEmail", data["user_email"]);
        localStorage.setItem("data", JSON.stringify(data));
        localStorage.setItem("sessions", JSON.stringify(data["sessions"]));
        // Use navigate to go to /profile page
        navigate("/chatbot");
      } else if (data["status"] === 409) {
        setErrorMessage("Wrong password or you haven't registered yet");
      }
    } catch (error) {
      console.error(error);
      setErrorMessage("An error occurred while logging in.");
    }
  };

  return (
    <div className="login-page">
      <div className="form">
        <form className="login-form" onSubmit={handleSubmit}>
          <input
            type="text"
            placeholder="your email"
            value={clientEmail}
            onChange={(e) => setClientEmail(e.target.value)}
          />
          <input
            type="password"
            placeholder="password"
            value={clientPassword}
            onChange={(e) => setClientPassword(e.target.value)}
          />
          <button type="submit">login</button>
          <p className="message">
            Not registered yet? <Link to="/signup">Create an account</Link>
          </p>
          <div>
            <p className="errorMessage">{errorMessage}</p>
          </div>
        </form>
      </div>
    </div>
  );
};

export default LoginForm;
