import React from "react";
import IntroductionText from "./IntroductionText";
import Header from "./Header";
import Footer from "./Footer";
import "../css/intro_style.scss";
import LuciaImage from "../images/Lucia.jpeg";
import BenImage from "../images/Ben.jpg";
import DavidImage from "../images/David.jpeg";
import PilleriinImage from "../images/Pilleriin.jpg";

const Intro = () => {
  return (
    <>
      <Header />
      {/* <SubPageHeading text="AIMIA: AI Mood Intervention Assistant" /> */}
      <IntroductionText
        title="OUR RESEARCH"
        content={
          <>
            <p style={{ marginBottom: "20px" }}>
              {" "}
              MIA is a large language model chatbot that provide self-help
              guidance to people with mental health issues. Our core principles
              are user safety and product trustworthiness and we take every
              measure to ensure that MIA's responses do not cause stress to our
              users. Before MIA's market entry, we commit to performing an
              exhaustive assessment and sharing our results.{" "}
            </p>

            <p>
              {" "}
              Our team is heavily engaged in comprehensive research projects.
              Our primary focus includes proposing an innovative framework to
              study the safety and information trustworthiness of mental
              wellness chatbots. We also plan to adjust our model to be
              equitable for users from various cultural backgrounds and
              investigate the effectiveness of MIA in mitigating anxiety and
              depressive symptoms. We welcome any potential collaboration
              interests and encourage you to reach out to us for involvement in
              our enlightening research projects.{" "}
            </p>
          </>
        }
      />

      <div
        id="MainTable"
        style={{ maxWidth: "40%", margin: "auto", marginTop: "5%" }}
      >
        <h1 className="subpage-heading">OUR RESEARCH TEAM</h1>
        <div className="research-subheading">
          {" "}
          {/* Updated style */}
          <img
            src={LuciaImage}
            alt="Lucia Chen"
            style={{ float: "left", marginRight: "15px", width: "30%" }}
          />
          <h3 style={{ marginBottom: "20px", marginTop: "8%" }}>
            Lucia Chen, Ph.D. in Computer Science (founder)
          </h3>
          <p>
            B.A. in Economics, M.A. in Applied Psychology. Lucia's PhD work
            focuses on using NLP for analyzing mental health on social media.
            She is now completing her postdoctoral scholar program at Stanford
            University, where her research focuses on machine learning fairness.
          </p>
        </div>

        {/* Member Ben Krause */}
        <div className="research-subheading">
          {" "}
          {/* Repeat the structure and style */}
          <img
            src={BenImage}
            alt="Ben Krause"
            style={{ float: "left", marginRight: "15px", width: "30%" }}
          />
          <h3 style={{ marginBottom: "20px" }}>
            Ben Krause, Ph.D. in Computer Science (co-founder)
          </h3>
          <p>
            B.A. Psychology, Ph.D Machine Learning. Ben is a specialist in
            generative language modeling who has been in the field for 8 years.
            He has developed and published multiple methods that improved the
            state of the art in language modeling, including the multiplicative
            LSTM architecture that was used by OpenAI in their early work on
            LLMs. He is also a former Senior Research Scientist at Salesforce
            where he designed a generative AI used by Gucci customer advisors to
            improve their sales conversation rates.
          </p>
        </div>

        <div className="research-subheading">
          {" "}
          {/* Repeat the structure and style */}
          <img
            src={DavidImage}
            alt="David Preece"
            style={{ float: "left", marginRight: "15px", width: "30%" }}
          />
          <h3 style={{ marginBottom: "20px" }}>
            David Preece, PhD; M.ClinPsych
          </h3>
          <p>
            PhD Clinical Psychology. David is a Clinical Psychologist and the
            Director of the Perth Emotion & Psychopathology Lab at Curtin
            University. His main research and practice interests are in the
            assessment, understanding, and treatment of emotional disorders and
            problems (e.g., depression, anxiety, loneliness). Much of his
            research focuses on the key role that emotion regulation
            difficulties play in a variety of mental health issues. David’s work
            explores how cognitive behavior therapy can be used to improve
            people’s emotion regulation skills, and in turn, improve people’s
            well-being across a range of areas.
          </p>
        </div>

        <div className="research-subheading">
          {" "}
          {/* Repeat the structure and style */}
          <img
            src={PilleriinImage}
            alt="Pillerin"
            style={{ float: "left", marginRight: "15px", width: "30%" }}
          />
          <h3 style={{ marginBottom: "20px" }}>
            Pilleriin Sikka, PhD in Psychology
          </h3>
          <p>
            B.Sc. and M.Sc. in Psychology, M.Sc. in Neuroscience. Pilleriin is
            currently a Postdoctoral Research Fellow at the Department of
            Psychology, Stanford University, and a Senior Lecturer in Cognitive
            Neuroscience at the Department of Cognitive Neuroscience and
            Philosophy, University of Skovde (Sweden). She conducts research on
            emotions and emotion regulation, and how these are related to health
            and well-being.
          </p>
        </div>

        {/* Ensure following content does not wrap around the floated images */}
        <div style={{ clear: "both" }}></div>
      </div>

      <Footer />
    </>
  );
};

export default Intro;
