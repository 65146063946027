import React, { useState, useEffect } from "react";
import axios from "axios"; // For HTTP requests (you'll need to install axios with npm/yarn)
import { useNavigate } from "react-router-dom"; /// Import useHistory
import CONFIG from "../config";
import { StatusCodes } from "http-status-codes";

// LoginForm component
const SignUpForm = () => {
  const [clientEmail, setClientEmail] = useState("");
  const [clientPassword, setClientPassword] = useState("");
  //const [clientName, setClientName] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isOver18, setIsOver18] = useState(false);
  const [agreedToTerms, setAgreedToTerms] = useState(false);
  const [capacityReached, setCapacityReached] = useState(false);
  // const [infoMessage, setInfoMessage] = useState("");

  const navigate = useNavigate(); // Get the navigate function

  //check if user needs to be on waiting list
  useEffect(() => {
    const checkCapacity = async () => {
      try {
        const response = await axios.get(`${CONFIG.BASE_URL}/check_capacity`);
        const data = response.data;
        setCapacityReached(data.capacity_reached);
      } catch (error) {
        console.error("Failed to check capacity:", error);
        // handle error appropriately
      }
    };
    checkCapacity();
  }, []);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    try {
      const postData = new FormData();
      postData.append("clientEmail", clientEmail);
      postData.append("pw1", clientPassword);
      // postData.append("clientName", clientName);

      const response = await axios.post(
        `${CONFIG.BASE_URL}/signup_submit`,
        postData,
        {}
      );

      const data = response.data;
      console.log(data);

      switch (data["status"]) {
        case 101:
          // Success
          // localStorage.setItem("username", clientName); // clientName from postData
          localStorage.setItem("userEmail", clientEmail); // clientEmail from postData
          navigate(capacityReached ? "/profile" : "/chatbot");
          break;
        case StatusCodes.FORBIDDEN:
          setErrorMessage("User already exists. Please try logging in.");
          break;
        case StatusCodes.CONFLICT:
          setErrorMessage(
            "Wrong password format. Please follow the required format."
          );
          break;
        case StatusCodes.REQUEST_TIMEOUT:
          setErrorMessage("Passwords don't match. Please try again.");
          break;
        case StatusCodes.NOT_ACCEPTABLE:
          setErrorMessage("Wrong password entered. Please try again.");
          break;
        default:
          setErrorMessage(
            "There was a problem with your signup attempt. Please check your email and try again."
          );
          break;
      }
    } catch (error: unknown) {
      console.error(error);
      let errorMessage =
        "An error occurred during registration. Please try again later.";

      // Check if the error is an instance of an Error, and has a 'response' property
      if (typeof error === "object" && error !== null && "response" in error) {
        const axiosError = error as { response: { data: { message: string } } };
        if (
          axiosError.response &&
          axiosError.response.data &&
          axiosError.response.data.message
        ) {
          // Assuming your backend sends specific error messages in the response
          errorMessage = axiosError.response.data.message;
        }
      }

      setErrorMessage(errorMessage);
    }
  };

  return (
    <div className="login-page">
      <div className="form">
        {capacityReached && ( // Conditionally render the waitlist message
          <div
            style={{
              backgroundColor: "#0669bf",
              padding: "10px",
              marginBottom: "20px",
              fontFamily: "Arial",
              textAlign: "left",
            }}
          >
            We have currently reached our testing capacity for Mia. You have
            been placed on a waiting list and will be notified via email when a
            spot becomes available. Thank you for your patience.
          </div>
        )}
        <form className="login-form" onSubmit={handleSubmit}>
          <p
            style={{
              color: "#31304D",
              marginBottom: "30px",
              marginRight: "40px",
              fontSize: "16px",
            }}
          >
            Please ensure your password consists of a minimum of six characters
            including at least one numeric digit, one uppercase letter, and one
            special symbol.
          </p>
          <input
            type="text"
            placeholder="your email"
            value={clientEmail}
            onChange={(e) => setClientEmail(e.target.value)}
          />
          {/* <input
            type="text"
            placeholder="your name"
            value={clientName}
            onChange={(e) => setClientName(e.target.value)}
          /> */}
          <input
            type="password"
            placeholder="password"
            value={clientPassword}
            onChange={(e) => setClientPassword(e.target.value)}
          />

          <div className="checkbox-container">
            <input
              className="checkbox-field"
              type="checkbox"
              checked={isOver18}
              onChange={(e) => setIsOver18(e.target.checked)}
            />
            <label className="checkbox-label">
              I declare that I am over 18 years old
            </label>
          </div>

          <div className="checkbox-container">
            <input
              className="checkbox-field"
              type="checkbox"
              checked={agreedToTerms}
              onChange={(e) => setAgreedToTerms(e.target.checked)}
            />
            <label className="checkbox-label">
              I agree with terms and conditions
            </label>
          </div>

          <button type="submit" disabled={!isOver18 || !agreedToTerms}>
            SignUp
          </button>
          {/* <p className="message">Not registered? <a href="./signup.html">Create an account</a></p> */}
          <div>
            <p className="errorMessage">{errorMessage}</p>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SignUpForm;
