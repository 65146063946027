import axios from "axios";
import CONFIG from "../config";

export const authenticateUser = async (apiKey) => {
  try {
    const postData = new URLSearchParams();
    postData.append("api_key_value", JSON.stringify(apiKey));

    const response = await axios.post(
      `${CONFIG.BASE_URL}/authenticate`,

      postData.toString(),
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("An error occurred during authentication", error);
    throw error; // Re-throw the error for caller to handle
  }
};
