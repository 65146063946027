import React from "react";
import Header from "./Header";
import LoginForm from "./LoginForm";
import "../css/loginstyle.scss";

const LoginPage = () => {
  return (
    <div className="header">
      {" "}
      {/* This div uses the header class for styling */}
      <Header />
      {/* <div className="chatButton-login"> */}
      <div className="form-and-chat">
        <LoginForm />
      </div>
    </div>

    // </div>
  );
};

export default LoginPage;
