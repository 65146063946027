import React from "react";
import IntroductionText from "./IntroductionText";
import Header from "./Header";
import Footer from "./Footer";
import "../css/intro_style.scss";

const Intro: React.FC = () => {
  return (
    <>
      <Header />
      {/* <SubPageHeading text="AIMIA: AI Mood Intervention Assistant" /> */}
      <IntroductionText
        title="WHAT IS MIA"
        content={
          <p>
            Mia is mental wellness chatbot that provides self-help guidance to
            people with anxiety issues. Our team is dedicated to creating
            innovative solutions to address the growing challenge of anxiety
            disorders among U.S adults, a population that currently stands at
            77.4 million. We understand that seeking professional help is the
            most effective way to manage anxiety and depression symptoms, but
            cost barriers often hinder access to treatment. Consequently, we've
            developed Mia, a state-of-the-art mental wellness chatbot powered by
            a generative model. Mia offers trustworthy self-help guidance to
            individuals dealing with anxiety-related problems, providing an
            affordable and accessible avenue for mental health support.{" "}
          </p>
        }
      />
      <IntroductionText
        title="WHO ARE WE"
        content={
          <>
            <p style={{ marginBottom: "20px" }}>
              Our team comprises multidisciplinary researchers focusing on the
              fields of machine learning, large language models, and the
              systematic study of diagnosing, analyzing, and offering solutions
              to emotional disorders. We blend our expertise in emotional
              regulation with our vision for research that benefits health and
              wellbeing.
            </p>

            <p>
              Our founding members,{" "}
              <a className="social-link" href="https://luciasalar.github.io/">
                Lucia Chen
              </a>{" "}
              and{" "}
              <a
                className="social-link"
                href="https://scholar.google.com/citations?user=ONNif60AAAAJ&hl=en&oi=ao"
              >
                Ben Krause
              </a>
              , are dedicated to creating digital tools that help those who
              cannot afford healthcare. Lucia earned a master's degree in
              Applied Psychology and a Ph.D. in Informatics from the University
              of Edinburgh. Her postdoctoral research at Stanford University,
              which centered on machine learning fairness, reinforces her
              commitment to developing ethical and accessible digital healthcare
              solutions.
            </p>
          </>
        }
      />
      <Footer />
    </>
  );
};

export default Intro;
