import React from "react";
import { useNavigate } from "react-router-dom";

const ChatButton: React.FC<ChatButtonProps> = ({ className, style }) => {
  //const buttonClass = className || 'chatButton-default';

  const navigate = useNavigate();

  const directChatbot = () => navigate("/chatbot");

  return (
    // <div className="container">
    <div className="chatButton-default">
      <button id="tochatbot" className="pulse-button" onClick={directChatbot}>
        Let's get started!
      </button>
    </div>
    // </div>
  );
};

export default ChatButton;
