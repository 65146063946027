import React from "react";
import "../css/intro_style.scss";
import twitterLogo from "../assets/logo-black.png";
import GithubLogo from "../assets/github-mark.png";

const Footer = () => {
  return (
    <footer className="content-footer">
      <p className="body-copy">Follow us on these social networks:</p>
      <ul className="social">
        <li>
          <a
            href="https://github.com/luciasalar"
            target="_blank"
            className="social-link"
            rel="noopener noreferrer"
            aria-label="Visit our Twitter page"
          >
            <img
              src={GithubLogo}
              alt="Github"
              style={{ width: "24px", height: "24px" }}
            />
          </a>
        </li>
        <li>
          <a
            href="https://twitter.com/ML_made_simple"
            target="_blank"
            className="social-link"
            rel="noopener noreferrer"
            aria-label="Visit our Twitter page"
          >
            <img
              src={twitterLogo}
              alt="Twitter"
              style={{ width: "24px", height: "24px" }}
            />
          </a>
        </li>
      </ul>
    </footer>
  );
};

export default Footer;
