import React from "react";
import Header from "./Header";
import SignUpForm from "./SignUpForm";
import "../css/loginstyle.scss";

const SignUpPage = () => {
  return (
    <div className="login-page-container">
      <div className="header">
        {" "}
        {/* This div uses the header class for styling */}
        <Header />
        <div
          className="form-and-chat"
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <SignUpForm />
        </div>
        {/* <Content /> */}
      </div>
    </div>
  );
};

export default SignUpPage;
