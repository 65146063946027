import React, { useState } from "react";
import logo from "../../assets/small_logo.gif";
import { sendState } from "../../hooks/chatbotService";

//displaying a single message within the chat interface.

const MessageItem = ({
  message,
  onRating,
  onRewriteInitiate,
  isExperiment,
}) => {
  const [isRewriting, setIsRewriting] = useState(false);
  const [rewrittenText, setRewrittenText] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [rewriteSubmitted, setRewriteSubmitted] = useState(false); // New state to track if rewrite is submitted

  // Check if there's actual content to display
  const hasContentToShow =
    message.originalText?.trim() ||
    message.text?.trim() ||
    (rewriteSubmitted && rewrittenText.trim());

  // Prevent rendering if there is no content
  if (!hasContentToShow) {
    return null;
  }

  const handleRewriteChange = (e) => {
    setRewrittenText(e.target.value);
  };

  const handleRewriteSubmit = () => {
    onRewriteInitiate(message.id, rewrittenText); // Pass the updated text back up for processing
    setIsRewriting(false); // Reset the rewriting state

    setRewriteSubmitted(true);
  };

  const toggleRewrite = () => {
    setIsRewriting(!isRewriting);
    setRewrittenText(message.text); // Initialize the form with the current message text
  };

  // select state function
  const handleStateChange = (event) => {
    setSelectedState(event.target.value);
  };

  const handleStateSubmit = async () => {
    if (selectedState) {
      await sendState(selectedState, message.id);
      // Optionally, do something after the state has been successfully sent, like notifying the user
    } else {
      alert("Please select a state before submitting.");
    }
  };

  return (
    <div className={`msg ${message.type === "bot" ? "left-msg" : "right-msg"}`}>
      <div
        className="msg-img"
        style={{
          backgroundImage: `url(${message.type === "bot" ? logo : "logo"})`,
        }}
      ></div>
      <div className="msg-bubble">
        <div className="msg-info">
          <div className="msg-info-name">{message.author}</div>
          <div className="msg-info-time">{message.timestamp}</div>
          {/* Rating buttons (for bot messages) */}
        </div>

        {message.originalText && (
          <div className="msg-original-text">{message.originalText}</div>
        )}

        <div className="msg-text">
          {/* Original text */}

          <div>
            {message.originalText ||
              message.text
                .split(/(?:\r\n|\r|\n)/g)
                .map((text, index, array) => (
                  <React.Fragment key={index}>
                    {text}
                    {index < array.length - 1 && <br />}
                  </React.Fragment>
                ))}
          </div>

          {rewriteSubmitted ? (
            // If yes, display the submitted rewritten text with the "Rewrite:" label
            <>
              <strong>Rewrite:</strong> {rewrittenText}
              <br />
            </>
          ) : // If the rewrite has not been submitted, check if the user is currently rewriting or displaying the original message
          isRewriting && isExperiment ? (
            <>
              <textarea value={rewrittenText} onChange={handleRewriteChange} />
              <button onClick={handleRewriteSubmit}>Submit</button>
            </>
          ) : null}

          {isExperiment && (
            <div className="msg-actions">
              <button onClick={toggleRewrite}>
                {isRewriting ? "Cancel" : "Rewrite"}
              </button>
            </div>
          )}
        </div>

        {message.type === "bot" && isExperiment && (
          <>
            {/* <div className="select-state">
              State:{" "}
              {message.stateInfo
                ? JSON.stringify(message.stateInfo)
                : "No state info"}
            </div>
            <div className="state-select-dropdown">
              <select value={selectedState} onChange={handleStateChange}>
                <option value="">Select a State</option>
                <option value="state1">State 1</option>
                <option value="state2">State 2</option>
                <option value="state3">State 3</option>
              </select>

              <button onClick={handleStateSubmit}>Submit State</button>
            </div> */}
          </>
        )}
      </div>
    </div>
  );
};

export default MessageItem;
