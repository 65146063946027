import React from "react";
import MessageItem from "./MessageItem";

const MessageList = ({
  messages,
  onRating,
  endOfMessagesRef,
  onRewriteInitiate,
  isExperiment,
}) => {
  return (
    <div className="message-list">
      {messages.map((message) => (
        <MessageItem
          key={message.id}
          message={message}
          onRating={onRating}
          onRewriteInitiate={onRewriteInitiate}
          isExperiment={isExperiment}
        />
      ))}
      {/* Attach the ref to an empty div at the end */}
      <div ref={endOfMessagesRef} />
    </div>
  );
};

export default MessageList;
