import React, { useState } from "react";

function MessageInputArea({ onMessageSubmit, onConversationEnd }) {
  const [message, setMessage] = useState("");

  // Function to handle form submission
  const handleSubmit = (e) => {
    e.preventDefault(); // Prevent the default form submission behavior

    if (message.trim()) {
      onMessageSubmit(message);
      setMessage(""); // Clear the input after sending
    }
  };

  // New function to handle conversation end without form submission
  const handleEndConversation = async (e) => {
    e.preventDefault(); // Prevent default form submission when 'End' button is clicked

    const summaryData = await onConversationEnd();
    console.log("Conversation Summary:", summaryData);
    // Add any additional logic here as needed
  };

  return (
    // Wrap inputs and buttons inside a form element and handle its `onSubmit` event
    <form className="msger-inputarea" onSubmit={handleSubmit}>
      <input
        type="text"
        className="msger-input"
        id="user-message"
        placeholder="Enter your message..."
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        name="user-message"
      />
      <button type="submit" className="msger-send-btn">
        Send
      </button>
      {/* Use a `button` with `type="button"` for the End button to prevent form submission */}
      {/* <button
        type="button"
        className="msger-send-btn"
        onClick={handleEndConversation}
      >
        End
      </button> */}
    </form>
  );
}

export default MessageInputArea;
