import { useState, useEffect } from "react";

//add chatbot message to a message queue

export const useChatbotMessages = (initialMessages) => {
  const [messages, setMessages] = useState(initialMessages);

  useEffect(() => {
    sessionStorage.setItem("chatMessages", JSON.stringify(messages));
  }, [messages]);

  const addMessage = (authorType, text, stateInfo, nodeType = {}) => {
    const timestamp = new Date().toLocaleTimeString();
    const newMessage = {
      id: Date.now(),
      author: authorType === "bot" ? "Mia" : "User",
      text,
      timestamp,
      type: authorType,
      stateInfo,
      nodeType,
    };

    setMessages((current) => [...current, newMessage]);
  };

  // Define the updateMessages function to allow updating the entire messages array
  const updateMessages = (updatedMessages) => {
    setMessages(updatedMessages);
  };

  return [messages, addMessage, updateMessages];
};
