import React, { useState, useEffect } from "react";
import Header from "./Header";
import ChatButton from "./ChatButton";
import { useWaitlistStatus } from "../hooks/chatbotService";
import { useNavigate } from "react-router-dom";

const ProfilePage = () => {
  const [summaries, setSummaries] = useState<Summary[]>([]);
  const [loading, setLoading] = useState(false);
  const [expandedSummaryId, setExpandedSummaryId] = useState<
    string | number | null
  >(null);
  const userEmail = localStorage.getItem("userEmail");
  const navigate = useNavigate();
  const {
    isOnWaitlist,
    //loading: waitlistLoading,
    // error,
  } = useWaitlistStatus(userEmail);

  useEffect(() => {
    getSummary();
  }, []);

  useEffect(() => {
    if (!userEmail) {
      navigate("/login"); // Redirect to login if no userEmail found.
      // Removed the redirection when `isOnWaitlist` is false because it seems unnecessary.
    }
  }, [navigate, userEmail]);

  const toggleSummary = (id: string | number): void => {
    setExpandedSummaryId(expandedSummaryId === id ? null : id);
  };

  const getSummary = async () => {
    setLoading(true);
    // Convert fetching logic to use FormData
    const sessionIds = JSON.parse(localStorage.getItem("sessions") || "[]");
    const userEmail = localStorage.getItem("userEmail");

    if (sessionIds.length === 0) {
      const sessionId = sessionStorage.getItem("uuid");
      if (sessionId) {
        sessionIds.push(sessionId);
      }
    }

    // const toggleSummary = (id) => {
    //   // Toggle the expanded summary, close if it's already open
    //   setExpandedSummaryId(expandedSummaryId === id ? null : id);
    // };

    const formData = new FormData();
    // Assuming the backend can handle session_ids_list and user_email as form data fields
    formData.append("session_ids_list", JSON.stringify(sessionIds));
    formData.append("user_email", JSON.stringify(userEmail));

    try {
      const response = await fetch("https://ophiuchi.ddns.net:2224/summary", {
        method: "POST",
        body: formData,
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      // Explicitly assert the type of the data
      const data: ResponseData = await response.json(); // Here's the change

      console.log(data);

      const isDataValid =
        data && typeof data === "object" && !Array.isArray(data);

      if (isDataValid) {
        const tempSummaries = Object.entries(data).reduce<Summary[]>(
          (acc, [key, value]) => {
            // Assuming all keys except 'status' hold the relevant data
            if (key !== "status" && value && value.summary && value.time) {
              acc.push({
                id: key,
                summary: value.summary,
                time: new Date(value.time).toLocaleString(),
              });
            }
            return acc;
          },
          []
        );

        setSummaries(
          tempSummaries.length > 0
            ? tempSummaries
            : [
                {
                  id: "0",
                  summary: "You don't have any conversation summary yet",
                  time: "",
                },
              ]
        );
      }
    } catch (error) {
      console.error("Error fetching summary: ", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <Header />

      <div className="chatButton-profile">
        {isOnWaitlist ? (
          <p>
            Thank you for signing up! You have been added to our waitlist.
            Please anticipate an email notification from us.
          </p>
        ) : (
          <ChatButton />
        )}
      </div>

      <div className="profile-background">
        {!isOnWaitlist && (
          <div>
            <div className="summary-btn-container">
              <button className="summary-btn" onClick={getSummary}>
                Get Summaries
              </button>
            </div>

            <div className="summary-form-container">
              {loading ? (
                <p>Loading summaries...</p>
              ) : summaries.length === 0 ? (
                <p>No summaries found.</p>
              ) : (
                summaries.map((summary) => (
                  <div key={summary.id} className="summary-container">
                    <div
                      onClick={() => toggleSummary(summary.id)}
                      className="summary-header"
                    >
                      {summary.time} {/* Display the date as clickable */}
                    </div>
                    {expandedSummaryId === summary.id && ( // Conditionally render the summary details
                      <div>
                        <p className="summary-text">{summary.summary}</p>
                      </div>
                    )}
                  </div>
                ))
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export default ProfilePage;
