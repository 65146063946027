import "../style.scss";
import { Link, useNavigate } from "react-router-dom";
import React from "react";

const Header: React.FC<HeaderProps> = ({ isChatbotPage = false }) => {
  const navigate = useNavigate();

  // const [isHovered, setIsHovered] = useState(false);
  const isLoggedIn = () => !!localStorage.getItem("userEmail");

  const handleLogout = () => {
    // Your logout logic here; presumably clearing localStorage/sessionStorage
    localStorage.clear();
    sessionStorage.clear();

    // Update the UI accordingly
    alert("You have now logged out of the system.");

    // Use navigate to redirect after logout
    navigate("/");
  };
  const navbarStyle = {
    //if not chatbot page, we use the gray color for the navigation bar
    backgroundColor: isChatbotPage ? "#EF7B45" : "#fafafa",
    // Other styles...
  };

  const linkButtonStyle = {
    background: "transparent",
    textDecoration: "none",
    fontSize: "16px",
    color: "#096278",
    fontFamily: "Verdana",
    fontWeight: "bold",
    display: "block",
    padding: "20px",
    transitionDuration: "0.6s",
    transitionTimingFunction: "cubic-bezier(0.68, -0.55, 0.265, 1.55)",
    cursor: "pointer",
    border: "none",
    // The commented property "position: relative;" is omitted. Uncomment the line below to include it
    // position: 'relative',
    // ...(isHovered ? { color: '#E63D37' } : {})
  };

  return (
    <nav className="navbar" style={navbarStyle}>
      <div className="collapse navbar-collapse" id="navbarSupportedContent">
        <ul className="navbar-nav ml-auto">
          <li className="nav-item">
            <Link className="nav-link" to="/">
              Main
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to="/intro">
              Intro
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to="/research">
              Research
            </Link>
          </li>

          {/* profile only shows after login */}
          {isLoggedIn() && (
            <li className="nav-item">
              <Link className="nav-link" to="/profile">
                Profile
              </Link>
            </li>
          )}

          {!isLoggedIn() && (
            <li className="nav-item">
              <Link className="nav-link" to="/signup">
                Sign Up
              </Link>
            </li>
          )}

          {!isLoggedIn() && (
            <li className="nav-item">
              <Link className="nav-link" to="/login">
                Login
              </Link>
            </li>
          )}

          {isLoggedIn() && (
            <li className="nav-item">
              <button
                onClick={handleLogout}
                className="nav-link"
                style={linkButtonStyle}
              >
                Logout
              </button>
            </li>
          )}
        </ul>
      </div>
    </nav>
  );
};

export default Header;
