import React from "react";
import "../css/intro_style.scss";
import Header from "./Header";
import ResourceBankText from "./ResourceBankText";
import BedroomImage from "../assets/bedroom.jpg";

const EmotionRegulationToolkit: React.FC = () => {
  return (
    <>
      <Header />

      <ResourceBankText
        title="Emotion Regulation Tool Kit: Behaviour Activation"
        content={
          <>
            <p>
              {/* <img
                src={BedroomImage}
                alt="Descriptive Alt Text"
                className="inlineImage"
              /> */}
              Our feelings, thoughts, and behaviours are all connected. This
              means that changing our behaviour patterns is a very effective way
              to help us feel better. Often when we are feeling down or
              stressed, we stop doing pleasurable and meaningful activities in
              our life. We might not feel motivated to do those activities we
              used to like, or we might feel like we don't have time for fun
              things. The problem with this is that it becomes part of a cycle -
              our life doesn't have many fun and meaningful activities in it,
              and that further drags down our mood. Behavioural activation is a
              strategy to help break this cycle. You do Behavioural Activation
              by scheduling fun and meaningful activities to do regularly
              throughout your week.
            </p>{" "}
            <br></br>
            <h3 className="paragraph-bg-2" color="white">
              Tips for Scheduling Fun & Meaningful Activities
              <br></br>
              <br></br>
              <br></br>
            </h3>
            <br></br>
            <div className="paragraph-bg-1">
              <h4>Get Specific & Build Healthy Habits</h4>
              <br></br>
              <p>
                When you are scheduling activities, it's important to set
                specific days/times to do specific activities. This is the key
                to Behavioral Activation. This helps us stick to our goal even
                on days when we don't feel like it. Try to schedule some
                activities that you can do regularly every week, rather than
                just one-off things, as this helps us build healthy habits in
                our life over time. There are many activities we could pick.
                Depending on what you like, some examples might be going for a
                walk, listening to music, reading a book, having a cup of tea,
                watching the sunset, doing yoga, watching a favorite TV show,
                sitting next to your pet, or having lunch with a friend.
              </p>
            </div>
            <br></br>
            <div className="paragraph-bg-1">
              <h4>Build Momentum with Your Behaviours</h4>
              <br></br>

              <p>
                To start with, you might not always feel like doing these
                activities, but by still doing them that is what will help build
                the behavioural momentum that flows into helping you feel better
                and having more energy over time. Over time it gets easier, and
                these activities become a normal and important part of our life.
                There will be days where you don't end up doing the activity you
                set, or you do a smaller version of it - that's ok, that's
                normal - the key is to reset and keep trying again the next day.
              </p>
            </div>
            <br></br>
            <div className="paragraph-bg-1">
              <h4>Finding the Right Balance</h4>
              <p>
                Some people also say "I'm too busy to do fun activities for
                myself" or "that's selfish" - but another way to think about
                this is that, if we don't take some time out for ourselves, we
                are going to burn out and not be properly productive in those
                things we are busy with. It's about balance. We all need time to
                recharge our batteries.
              </p>
            </div>
            <br></br>
            <div className="paragraph-bg-1">
              <h4>One Step at a Time</h4>
              <br></br>
              <p>
                The activity goals you set can be bigger, like "every weekday at
                6pm I'm going to go to the gym for 1 hour", or smaller, like "on
                Saturdays at 8am I'm going to read my book with a cup of tea for
                15 minutes". Try to pick things that are enjoyable for you or
                give you a sense of meaning and achievement. If not much is
                enjoyable for you right now, pick activities that technically
                should be enjoyable or that you enjoyed in the past - sometimes
                we need to "go through the motions" behaviourally for a while,
                and that helps break the cycle of stress. If you're having
                trouble doing the activities you set, try to break it down to a
                smaller goal and build from there - even if it's not perfectly
                what you want, the secret is getting the momentum going, one
                step at a time.
              </p>
            </div>
            <br></br>
            <div className="paragraph-bg-1">
              <h4>Getting Your Body Moving & Going in Meaningful Directions</h4>
              <br></br>
              <p>
                Having some of your scheduled activities in the week be about
                physical exercise can often be a great idea, as regular exercise
                can be a great stabiliser in our life and it releases endorphins
                in our body that reduce stress. Think also about what matters to
                you and the directions you want your life to go.
              </p>
            </div>
            <h5>Author: David Preece</h5>
          </>
        }
      />
    </>
  );
};

export default EmotionRegulationToolkit;
