import React, { useState } from "react";

const APIKeyInputForm = ({ onSubmit }) => {
  const [apiKey, setApiKey] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(apiKey);
  };

  return (
    <form onSubmit={handleSubmit} className="api-inputarea">
      <input
        type="text"
        className="api-input"
        placeholder="Please enter an API key..."
        value={apiKey}
        onChange={(e) => setApiKey(e.target.value)}
      />
      <button type="submit" className="api-send-btn">
        Save
      </button>
    </form>
  );
};

export default APIKeyInputForm;
