import dotenv from "dotenv";
dotenv.config();

const CONFIG = {
  BASE_URL: "https://ophiuchi.ddns.net:2224", // Make sure this is correct and reachable

  //BASE_URL: "https://aimia.site",

  SECRET_KEY: process.env.SECRET_KEY,
};
export default CONFIG;
