import React from "react";
import Header from "./Header";
import MainTitle from "./MainTitle";
import Waves from "./Waves";

import FlexComponent from "./flexComponent";

const HomePageHeader = () => {
  return (
    <div className="header">
      {/* {" "} */}
      <Header />
      <MainTitle />
      <Waves />
      {/* <Content /> */}

      <FlexComponent />
    </div>
  );
};

export default HomePageHeader;
