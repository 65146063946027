import React, { useState } from "react";

const FlexComponent: React.FC = () => {
  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);

  const handleMouseEnter = (index: number) => setHoveredIndex(index);
  const handleMouseLeave = () => setHoveredIndex(null);

  return (
    // <div className="header-content">
    <div className="flex-container">
      <div
        className="flex-slide zero"
        onMouseEnter={() => handleMouseEnter(0)} // Correct index already here
        onMouseLeave={handleMouseLeave}
      >
        <div
          className="flex-title flex-title-zero"
          style={{
            transform: hoveredIndex === 0 ? "rotate(0deg)" : "rotate(90deg)",
            top: hoveredIndex === 0 ? "10%" : "15%",
          }}
        >
          CBT strategies
        </div>
        <div
          className="flex-about flex-about-zero"
          style={{
            opacity: hoveredIndex === 0 ? "1" : "0",
          }}
        >
          <p>
            Mia offers personalized coaching in Cognitive Behavior Therapy (CBT)
            strategies. This is a set of techniques that can help you to cope
            with stress by changing your thinking and behavior patterns. These
            strategies are designed to help you improve your well-being, build
            healthier habits, and achieve your personal and professional goals.
            With customizable feedback and reminders, Mia allows you to stay on
            track with your journey towards a more fulfilling and balanced life.
          </p>
        </div>
      </div>

      {/* Changed onMouseEnter to point to 1 for the second div */}
      <div
        className="flex-slide one"
        onMouseEnter={() => handleMouseEnter(1)} // Changed index to 1
        onMouseLeave={handleMouseLeave}
      >
        <div
          className="flex-title flex-title-one"
          style={{
            transform: hoveredIndex === 1 ? "rotate(0deg)" : "rotate(90deg)", // Changed condition to check for 1
            top: hoveredIndex === 1 ? "10%" : "15%",
          }}
        >
          Transdiagnostics
        </div>
        <div
          className="flex-about flex-about-one"
          style={{
            opacity: hoveredIndex === 1 ? "1" : "0", // Changed condition to check for 1
          }}
        >
          <p>
            The strategies that Mia teaches are 'transdiagnostic', which means
            they are relevant and helpful across a wide range of different
            mental health issues. This allows Mia to be flexible in working with
            you across many areas in your life. By working with Mia you can gain
            a deeper understanding of your emotions, thoughts, and behaviors,
            develop healthy coping mechanisms, and improve your overall
            well-being. With Mia as your guide, you can unlock the advantages of
            these strategies and discover new ways to overcome obstacles and
            thrive. Start your journey towards a happier, healthier you today.
          </p>
        </div>
      </div>

      <div
        className="flex-slide two"
        onMouseEnter={() => handleMouseEnter(2)} // Changed index to 1
        onMouseLeave={handleMouseLeave}
      >
        <div
          className="flex-title flex-title-two"
          style={{
            transform: hoveredIndex === 2 ? "rotate(0deg)" : "rotate(90deg)", // Changed condition to check for 1
            top: hoveredIndex === 2 ? "10%" : "15%",
          }}
        >
          Memory
        </div>
        <div
          className="flex-about flex-about-two"
          style={{
            opacity: hoveredIndex === 2 ? "1" : "0", // Changed condition to check for 1
          }}
        >
          <p>
            Mia has a built-in memory system, designed to enhance user
            experience like never before. This unique feature allows Mia to
            remember important details about the your life experiences, goals
            you have set, and preferences, creating a personalized and tailored
            interaction. By keeping track of this information, Mia can provide
            more relevant and helpful responses. Say goodbye to repetitive
            interactions and hello to a more intuitive and seamless chatbot
            experience.{" "}
          </p>
        </div>
      </div>

      <div
        className="flex-slide three"
        onMouseEnter={() => handleMouseEnter(3)} // Changed index to 1
        onMouseLeave={handleMouseLeave}
      >
        <div
          className="flex-title flex-title-three"
          style={{
            transform: hoveredIndex === 3 ? "rotate(0deg)" : "rotate(90deg)", // Changed condition to check for 1
            top: hoveredIndex === 3 ? "10%" : "15%",
          }}
        >
          Profile
        </div>
        <div
          className="flex-about flex-about-three"
          style={{
            opacity: hoveredIndex === 3 ? "1" : "0", // Changed condition to check for 1
          }}
        >
          <p>
            Mia also offers a user profile. In this profile, you can track your
            progress, see a summary of each conversation, monitor your mood
            fluctuations, and stay updated on the strategies you are learning.
            With this innovative tool, you can easily reflect on your growth and
            see how your efforts are positively impacting your mental health
            journey.
          </p>
        </div>
      </div>
    </div>
  );
};

export default FlexComponent;
