import React from "react";

const AgreementComponent = ({ onAgree }) => {
  return (
    <div className="tacbox" id="tacbox1">
      <label htmlFor="button1">
        <h2 style={{ textAlign: "center" }}>Welcome to AIMIA!</h2>
        <p>
          Before we get started, please read and accept our terms and
          conditions.
        </p>
        <p>By clicking "Agree," you acknowledge that...</p>
        <ul className="indented-list">
          <li>
            This is a research tool and not a substitute for professional
            therapy.
          </li>
          <li>
            The AI could produce any type of responses, including potentially
            harmful ones.
          </li>
          <li>
            You agree not to hold this website or its creators liable for the
            AI's statements.
          </li>
        </ul>

        <div style={{ height: "60px" }}></div>

        {/*<p style={{ height: "70px" }}>Please review the <a href="#terms">Terms and Conditions</a> for more details.</p>
         */}
      </label>
      <button onClick={onAgree} className="agree-btn">
        Agree
      </button>
    </div>
  );
};

export default AgreementComponent;
