import React from "react";
import logo from "../assets/logo_ver4.png";
import ChatButton from "./ChatButton";

const MainTitle = () => {
  // Splitting the title into parts to isolate "AIM" and "IA"
  const titlePart1 = "AI";
  const titlePart2 = "IA";

  return (
    <div className="header-content">
      <div className="container">
        <div className="inner-header flex">
          {/* Rendering the title with the logo replacing the "M" */}
          <h2 id="maintitle">
            {titlePart1}
            <img src={logo} alt="M" className="logoInTitle" />
            {titlePart2}
          </h2>
          <h3 id="subtitle">AI mood intervention assistant</h3>
          <div className="chatButton-container-main">
            <ChatButton />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainTitle;
