import React from "react";
import iosIcon from "../assets/apple_store.png"; // Assuming you have an iOS icon in your assets
import androidIcon from "../assets/google-play-badge.png";

const MobileDownload = () => {
  return (
    <div style={{ textAlign: "center", marginTop: "50px" }}>
      <p>Please download our mobile application for a better experience.</p>
      {/* iOS Download */}
      <a
        href="https://apps.apple.com/app/idYOUR_IOS_APP_ID"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          src={iosIcon}
          alt="Download on the App Store"
          style={{ width: "150px", marginRight: "10px" }}
        />
      </a>
      {/* Android Download */}
      <a
        href="https://play.google.com/store/apps/details?id=YOUR_ANDROID_APP_ID"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          src={androidIcon}
          alt="Get it on Google Play"
          style={{ width: "150px" }}
        />
      </a>
    </div>
  );
};

export default MobileDownload;
